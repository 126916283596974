<template>
  <section id="modal-redefinicao-senha">
    <div class="modal-redefinicao-senha-bg" @click="fecharModal"></div>
    <div class="modal-redefinicao-senha-content">
      <div>
        <transition name="fade-left" mode="out-in" appear>
          <div class="etapa-envio" v-if="etapa == 'etapa1'" key="etapa1">
            <h2>Quase lá!</h2>
            <p>para sua segurança vamos enviar um código de segurança<br />escolha como prefere receber:</p>
            <form @submit.prevent>
              <div class="redefinicao-email">
                <label for="redefinicao-email"
                  >email para: <span>{{ email }}</span></label
                >
                <input checked type="radio" id="redefinicao-email" name="redefinicao-radio-input" value="EnviarPorEmail" v-model="contato" />
              </div>
              <div class="redefinicao-sms">
                <label for="redefinicao-sms"
                  >sms para: <span>{{ celular }}</span></label
                >
                <input type="radio" id="redefinicao-sms" name="redefinicao-radio-input" value="EnviarPorSms" v-model="contato" />
              </div>
              <button id="enviar-codigo" class="btn btn-redefinir-senha" @click="enviarCodigo">Enviar</button>
            </form>
          </div>
          <div class="etapa-codigo" v-else-if="etapa == 'etapa2'" key="etapa2">
            <h2>Quase lá!</h2>
            <span id="etapa-envio-contato" v-html="mensagem"></span>
            <p>digite abaixo o código que você recebeu!</p>
            <div class="box-codigo-sms">
              <input type="text" id="codigo-sms" maxlength="6" v-model="codigo" />
            </div>
            <a id="etapa-codigo-reenviar" @click="etapa = 'etapa1'">Reenviar código</a>
            <button class="btn btn-redefinir-senha" id="enviar-nova-senha" @click="finalizarRedefinicao" :disabled="!codigo || codigo.length < 6">
              Enviar
            </button>
          </div>
        </transition>
      </div>
    </div>
    <div></div>
  </section>
</template>

<script>
import { enviarCodigo, enviarSenha } from '@/services/redefinirSenha';
export default {
  name: 'RedefinicaoSenha',
  data() {
    return {
      etapa: 'etapa1',
      email: null,
      celular: null,
      contato: 'EnviarPorEmail',
      mensagem: null,
      codigo: null,
    };
  },
  computed: {
    senhaRedefinicao() {
      return this.$store.state.user.senhaRedefinicao;
    },
  },
  methods: {
    enviarCodigo() {
      const data = {
        Cpf: JSON.parse(sessionStorage.getItem('redefinicao')).cpfCompleto,
        Sistema: 4,
        EnviarPorEmail: this.contato == 'EnviarPorEmail' ? true : false,
        EnviarPorSms: this.contato == 'EnviarPorSms' ? true : false,
        NovaSenha: this.senhaRedefinicao,
      };
      enviarCodigo(data).then((resp) => {
        if (!resp.data.sucesso && resp.data.mensagem == 'A nova senha deve ser diferente da senha atual') {
          this.$emit('fecharModal');
          return this.$store.commit('SET_ALERTA', {
            ativado: true,
            mensagem: resp.data.mensagem,
            tipo: 'erro',
          });
        }
        if (!resp.data.sucesso) {
          this.mensagem = resp.data.mensagem;
        } else {
          this.mensagem = `Código enviado para ${this.contato == 'EnviarPorEmail' ? JSON.parse(sessionStorage.getItem('redefinicao')).email : JSON.parse(sessionStorage.getItem('redefinicao')).celular}<br />Caso não receba dentro de <span class="minutos">5:00 minutos</span>, tente novamente.`;
        }
        this.etapa = 'etapa2';
      });
    },
    finalizarRedefinicao() {
      const data = {
        Password: this.senhaRedefinicao,
        Token: this.codigo.toUpperCase(),
        Cpf: JSON.parse(sessionStorage.getItem('redefinicao')).cpfCompleto,
      };
      enviarSenha(data).then((resp) => {
        if (!resp.data.sucesso) {
          if (resp.data.mensagem != 'Código inválido') {
            this.$emit('fecharModal');
          }
          return this.$store.commit('SET_ALERTA', {
            ativado: true,
            mensagem: resp.data.mensagem,
            tipo: 'erro',
          });
        }
        localStorage.setItem('token', resp.data.token);
        this.$router.push('/');
      });
    },
    fecharModal() {
      this.$emit('fecharModal');
    },
  },
  mounted() {
    this.email = JSON.parse(sessionStorage.getItem('redefinicao')).email;
    this.celular = JSON.parse(sessionStorage.getItem('redefinicao')).celular;
  },
};
</script>

<style lang="scss">
#modal-redefinicao-senha {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  .modal-redefinicao-senha-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.65);
  }
  .modal-redefinicao-senha-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 1080px;
    height: 100%;
    margin: 0 auto;
    @media (max-width: 630px) {
      max-width: 100%;
    }
    > div {
      position: relative;
      width: 500px;
      padding: 20px 20px 20px 110px;
      box-sizing: border-box;
      @media (max-width: 630px) {
        width: 100%;
        padding: 20px 30px;
      }
      > * {
        position: relative;
        @media (max-width: 630px) {
          max-width: 350px;
          margin: 0 auto;
        }
      }
      &:before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100vw;
        height: 100vh;
        content: '';
        transform: translateY(-50%);
        background: #bee82f;
      }
      .etapa-envio {
        h2 {
          font-size: 39px;
          color: #58595b;
          margin-bottom: 15px;
          @media (max-width: 960px) {
            text-align: center;
          }
        }
        p {
          color: #58595b;
          font-size: 19px;
          line-height: 24px;
          margin-bottom: 20px;
          @media (max-width: 960px) {
            text-align: center;
          }
        }
        form {
          div {
            width: 100%;
            padding-right: 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 630px) {
              padding-right: 0;
            }
          }
          label {
            width: calc(100% - 35px);
            font-size: 13px;
            font-weight: 600;
            color: #58595b;
            padding: 10px 0;
            cursor: pointer;
            border-bottom: 1px solid #58595b;
          }
          input[type='radio'] {
            border: 0px;
            width: 20px;
            height: 2em;
            outline: none;
            cursor: pointer;
          }
          button {
            margin-top: 20px;
          }
        }
      }
      .etapa-codigo {
        h2 {
          font-size: 39px;
          color: #58595b;
          margin-bottom: 15px;
          @media (max-width: 960px) {
            text-align: center;
          }
        }
        p {
          color: #58595b;
          font-size: 19px;
          line-height: 24px;
          margin: 10px 0;
          @media (max-width: 960px) {
            text-align: center;
          }
        }
        > span {
          font-size: 15px;
          color: #58595b;
          line-height: 1.2em;
          .minutos {
            font-weight: 700;
            color: #6521e3;
          }
          @media (max-width: 960px) {
            display: block;
            text-align: center;
          }
        }
        .box-codigo-sms {
          position: relative;
          width: 100%;
          #codigo-sms {
            position: relative;
            width: 100%;
            outline: none;
            border: none;
            background: none;
            color: #58595b;
            text-align: left;
            letter-spacing: 0.29em;
            font-family: Courier, monospace;
            font-size: 50px;
          }
          &:before {
            position: absolute;
            bottom: 5px;
            left: 0;
            display: block;
            width: 100%;
            text-align: left;
            height: auto;
            color: #58595b;
            content: '______';
            letter-spacing: 0.3em;
            font-family: Courier, monospace;
            font-size: 50px;
          }
          @media (max-width: 960px) {
            text-align: center;
            margin: 0 auto;
            max-width: 240px;
            #codigo-sms {
              font-size: 45px;
            }
            &:before {
              content: '______';
              font-size: 45px;
              text-align: left;
            }
          }
        }
        a {
          font-size: 10px;
          text-transform: uppercase;
          text-decoration: underline;
          color: #58595b;
          margin: 20px 0;
          display: block;
          cursor: pointer;
          @media (max-width: 960px) {
            text-align: center;
          }
        }
      }
    }
  }
  .btn-redefinir-senha {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 132px;
    height: 42px;
    cursor: pointer;
    transition: 0.3s;
    border: none;
    border-radius: 25px;
    outline: none;
    background: #6521e3;
    &:hover {
      background: darken(#6521e3, 5%);
    }
    &:disabled {
      cursor: default;
      background: $cinza1;
    }
    @media (max-width: 900px) {
      margin: 0 auto 20px auto;
    }
  }
}
</style>
