import axios from 'axios';
import store from '@/store';
import { _rotasAPI } from '@/services/_caminhosApi';
//
export function enviarCodigo(data) {
  return new Promise((resolve) => {
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().enviarCodigoRedefinicao, data)
      .then((resp) => {
        resolve(resp);
      })
      .catch(() => {
        store.commit('SET_ALERTA', {
          ativado: true,
          mensagem: 'Falha na solicitação.',
          tipo: 'erro',
        });
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function enviarSenha(data) {
  return new Promise((resolve) => {
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().enviarSenhaRedefinicao, data)
      .then((resp) => {
        resolve(resp);
      })
      .catch(() => {
        store.commit('SET_ALERTA', {
          ativado: true,
          mensagem: 'Falha na solicitação',
          tipo: 'erro',
        });
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
